@import '../../../../../styles/customMediaQueries.css';

.priorityLinkWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: right;
}

.priorityLink {
  transition: var(--transitionStyleButton);
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 120%;
  letter-spacing: 0;
  color: var(--colorGrey700);
  display: inline-flex;
  align-items: center;
  flex-shrink: 0;
  height: 100%;
  padding: 10px 12px;
  margin: 0;

  &:hover {
    color: var(--colorBlack);
    border-radius: 0;
    text-decoration: none;
  }

  @media (--viewportMedium) {
    line-height: 16px;
    margin: 0;
  }
}

.highlight {
  color: var(--colorGrey700);
  &:hover {
    color: var(--colorBlack);
  }
}

.priorityLinkLabel {
  font-size: 15px;
  line-height: 120%;
  display: inline-block;
  text-decoration: inherit;
  text-wrap: nowrap;
}
